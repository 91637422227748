.drift-left-nav {
  position: fixed;
}

.share-gif-page {
  height: 100vh;
  width: 100%;
  background-color: var(--color-p1-4);
}

#unified-root .share-gif-page {
  margin-left: 0;
  width: 100%;
}

.share-gif-page .tide-header-nav--container {
  height: 88px;
}

.share-gif-page .tide-header-nav--title-actions {
  display: block;
  top: 0px;
  left: 20px;
  right: 20px;
}

.share-gif-page .tide-base-modal {
  z-index: 1;
}

.share-gif-page-header {
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.share-gif-page-title-and-options {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  margin-top: 2px;
}

.share-gif-page-title-and-options .video-title-container {
  margin-top: 7px;
}

.share-gif-page-content {
  display: flex;
  padding: 16px;
  min-height: 85%;
  background-color: var(--color-p1-4);
}
