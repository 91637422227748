.customer-preview-button.tide-button {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 32px;
}

.customer-preview-button .tide-icon {
  position: relative;
  top: 2px;
}
