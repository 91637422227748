.share-video-page.how-to-video {
  background: white;
}

.logo-header-banner {
  width: 130px;
  height: 16px;
  margin: 24px 40px 0;
}

.content-container {
  width: 100% !important;
  height: calc(100vh - 48px) !important;
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

.content-container .video-container {
  margin: 0;
}

.video-data-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 24px;
}

.video-info h1 {
  font-family: var(--main-font-family--extrabold);
  margin-top: 0;
}

.video-info p {
  margin-top: 8px;
}

.video-actions {
  display: flex;
  align-items: center;
}

.recreate-video {
  margin-left: 8px;
}
