.drift-left-nav {
  position: fixed;
}

.share-video-page {
  overflow: scroll;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: var(--color-p1-4);
}

.share-video-page__container {
  padding: 0;
  background-color: var(--color-p1-4);
}

.share-video-page .tide-header-nav--container {
  height: 88px;
}

.share-video-page .tide-header-nav--title-actions {
  top: 0px;
  left: 20px;
  right: 20px;
  display: block;
}

.share-video-page__title-and-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  margin-left: 0px;
}

.share-video-page__options {
  display: flex;
  align-items: center;
}

.share-video-page__title-and-options .video-title-container {
  margin-top: 7px;
}

.share-video-page__content {
  display: flex;
  background-color: var(--color-p1-4);
  padding: 22px 16px 20px 16px;
}

.share-video-page__content .video-sharing-options {
  flex: 1 0 320px;
}

@media (max-width: 900px) {
  .share-video-page__content {
    display: block;
  }
}

@media (min-width: 1500px) {
  .share-video-page__content {
    max-width: 1500px;
    margin: auto;
  }
}

.video-share__toast-container {
  position: fixed;
  left: 64px;
  bottom: 20px;
  z-index: 3;
}
