.page-header-buttons-container {
  display: flex;
}

.page-header-buttons-container .icon-button {
  width: 34px;
  height: 32px;
  padding: 4px;
  margin-left: 8px;
}

.page-header-buttons-container .icon-button svg {
  margin: 1px 2px 0 !important;
}

.page-header-buttons-container .more-options-button {
  margin-left: 8px;
}

.more-options-button .tide-button {
  padding: 6px 10px 4px;
  height: 100%;
}

.more-options-button .icon-left .tide-icon {
  margin: 0;
  transform: scale(1.5);
}

.page-header-buttons-container .tide-action-dropdown-actions {
  right: 0;
  z-index: 1;
}

.more-options-button .scissors-icon {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.tide-base-modal.tide-base-modal--open {
  z-index: 2;
}

.embed-upgrade-ribbon {
  cursor: pointer;
  margin-top: 8px;
}

.embed-upgrade-cta-link {
  display: inline;
}

.embed-disabled-copy-button {
  display: block;
  margin-top: 24px;
}
