.drift-video-onboarding-success-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'ProximaNova-Regular';
  color: var(--color-p1-10);
  animation: hideSuccessOverlay 4s forwards;
}

.drift-video-onboarding-success-overlay img {
  height: 200px;
  width: 258px;
  margin-bottom: 32px;
}

.success-overlay-header {
  font-size: 28px;
  margin-bottom: 12px;
}

@keyframes hideSuccessOverlay {
  0% {
    opacity: 1;
    z-index: 100;
  }
  80% {
    opacity: 1;
    z-index: 100;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
